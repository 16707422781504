import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import useWindowDimensions from './Measure';
import { NavLink } from 'react-router-dom';

function usePip(ref) {
  const { height, width } = useWindowDimensions();
  const [boxSize, setBoxSize] = useState({ width: 360, height: 56 });

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setBoxSize({ width: rect.width, height: rect.height });
    }
  }, []);

  const [styles, set] = useSpring(() => ({
    x: width / 2 - boxSize.width / 2,
    y: height - boxSize.height - 5,
  }));

  useEffect(() => {
    const handleResize = () => {
      const rect = ref.current.getBoundingClientRect();
      setBoxSize({ width: rect.width, height: rect.height });

      const defaultX = rect.x + rect.width / 2 > window.innerWidth / 4
        ? window.innerWidth / 2 - rect.width / 2
        : rect.x + rect.width / 2 > window.innerWidth / 1.5
        ? window.innerWidth - 28 - rect.width
        : styles.x.get();

      const defaultY = rect.y + rect.height / 2 > window.innerHeight / 4
        ? window.innerHeight - 5 - rect.height
        : rect.y + rect.height / 2 > window.innerHeight / 1.5
        ? window.innerHeight - 28 - rect.height
        : styles.y.get();

      set({ x: defaultX, y: defaultY });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bind = useDrag(
    (_a) => {
      var { down, last, movement: [x, y], metaKey } = _a;
      let defaultX = 28;
      let defaultY = 18;
      if (last && ref.current) {
        const rect = ref.current.getBoundingClientRect();

        if (rect.x + rect.width / 2 > window.innerWidth / 4) {
          defaultX = window.innerWidth / 2 - rect.width / 2;
        }
        if (rect.x + rect.width / 2 > window.innerWidth / 1.5) {
          defaultX = window.innerWidth - 28 - rect.width;
        }
        if (rect.y + rect.height / 2 > window.innerHeight / 4) {
          defaultY = window.innerHeight / 2 - rect.height / 2;
        }
        if (rect.y + rect.height / 2 > window.innerHeight / 1.5) {
          defaultY = window.innerHeight - 5 - rect.height;
        }
      }
      set({
        x: down || metaKey ? x : defaultX,
        y: down || metaKey ? y : defaultY,
        immediate: down,
      });
    },
    {
      initial: () => [styles.x.get(), styles.y.get()],
    }
  );

  return [
    bind,
    {
      ...styles,
      position: "fixed",
      left: 0,
      top: 0,
      zIndex: 999999,
    },
  ];
}

function Simple() {
  const ref = useRef(null);
  const [props, styles] = usePip(ref);

  return (
    <>
      <animated.div ref={ref} {...props()} style={{ ...styles }}>
        <div className="top-bar">
          <nav className="nav">
            <NavLink
              to="/approach"
              className="nav-item"
              activeClassName="is-active"
            >
              <span active-color="black">Approach</span>
            </NavLink>
            <NavLink
              exact
              to="/"
              className="nav-item"
              activeClassName="is-active"
            >
              <span active-color="black">Work</span>
            </NavLink>
            <NavLink
              to="/info"
              className="nav-item"
              activeClassName="is-active"
            >
              <span active-color="black">Info</span>
            </NavLink>

            <span className="nav-indicator"></span>
          </nav>
        </div>
      </animated.div>
    </>
  );
}

export default Simple;
