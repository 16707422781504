import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      // Scroll after the page has fully loaded/rendered
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    // Use a small delay to ensure the new route has rendered before scrolling
    const timeoutId = setTimeout(() => {
      handleScroll();
    }, 100); // Adjust this delay if needed

    return () => clearTimeout(timeoutId); // Cleanup on unmount
  }, [pathname]);

  return null;
}

export default ScrollToTop;
