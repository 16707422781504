import './App.css';
import React, { useState, useEffect, Suspense } from 'react';
import MenuJS from './Components/MenuJS';
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import { AnimatePresence } from 'framer-motion';
import ScrollToTop from './Components/ScrollToTop'; // Import ScrollToTop

const Main = React.lazy(() => import('./Main'));

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

function App() {
  const [loading, setLoading] = useState(true);
  const [dark, setDark] = useState(false);
  const [categories, setCategories] = useState([]);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    const onScroll = debounce(() => {
      setLastScrollY(window.scrollY);
    }, 100);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Router> {/* Wrap the whole app with Router */}
      <ScrollToTop /> {/* Add ScrollToTop here to reset scroll on route change */}
      <MenuJS />

      <Suspense fallback={
        <div className="preload-wrapper">
          <div className="lds-spinner">
            <div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div>
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
      }>
        <Main /> {/* Main component that has the routes */}
      </Suspense>
    </Router>
  );
}

export default App;
